import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { BRAND_NAME } from '@/utils/constant'
import { isMobile } from '@/utils/helpers'
const Layout = () => import('@/layout/index.vue')

function getComponentSuffix(): '' | '-h5' {
  return isMobile() ? '-h5' : ''
}

declare module 'vue-router' {
  interface _RouteRecordBase {
    hidden?: boolean // 是否展示在菜单中
  }

  interface RouteMeta {
    title: string
    icon?: string
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: isMobile() ? '/top-up' : '/home', // 移动端没有 home 首页
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/login-h5',
    component: () => import('@/views/login/login-h5.vue'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/',
    component: Layout,
    redirect: isMobile() ? '/top-up' : '/home', // 移动端没有 home 首页
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: 'Home',
        },
      },
      {
        path: '/top-up',
        alias: '/c',
        name: 'topUp',
        component: () => import(`@/views/top-up/index${getComponentSuffix()}.vue`),
        meta: {
          title: 'Top Up',
          border: true,
        },
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

function getDocumentTitle(title: string) {
  return `${title} - ${BRAND_NAME}`
}

router.afterEach((to) => {
  document.title = getDocumentTitle(to.meta.title)
})

export default router
