export const BRAND_NAME = 'JoyReels' // 品牌名
export const BRAND_EMAIL = 'service@joyreels.com'
export const APP_STROE_DEEP_URL = 'https://joyreels.onelink.me/HmDG/60lwp0px' // APP 深度链接

export const APP_STROE_URL = {
  ios: 'https://apps.apple.com/app/joyreels/id6465895541',
  google: 'https://play.google.com/store/apps/details?id=com.joyreels.video',
}

export const APP_AGREEMENT_ADDRESS = {
  terms: 'https://www.joyreels.com/user_agreement/index.html',
  privacy: 'https://www.joyreels.com/privacy_policy/index.html',
}

// 本地存储键名 localStorage
export const TOKEN_KEY = 'token'
export const DRIVER_KEY = 'driver'
export const PAY_CARD_SAVED_KEY = 'payCardSaved'

// 会话存储键名 sessionStorage
export const ORDER_CONTSENT_CACHE_KEY = 'ORDER_CONTSENT_CACHE' // 订单缓存
