/**
 * 将给定字符串的第一个字符转换为大写，并返回新的字符串。
 * @param str 待转换的字符串。
 * @returns 转换后的新字符串，其中第一个字符为大写。
 */
export function capitalizeFirstChar(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * 检查当前设备是否为移动设备。
 * 该函数通过检测用户代理（User Agent）字符串中是否包含特定的移动设备标识符来判断。
 *
 * @returns {boolean} 如果检测到当前设备是移动设备，则返回 `true`；否则返回 `false`。
 */
export function isMobile(): boolean {
  // 使用正则表达式匹配用户代理字符串，以判断当前设备是否为移动设备
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

// 判断是否是苹果操作系统
export function isAppleOS(): boolean {
  return /iPhone|iPad|iPod|macintosh|mac os x/i.test(navigator.userAgent)
}
